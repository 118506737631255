import React, {Component} from 'react';
import SceneComponent from 'babylonjs-hook';
import './App.css';
import {  Vector3, Layer,  AssetsManager, ArcRotateCamera, StandardMaterial, Color3, ActionManager,  ExecuteCodeAction,  Color4 } from '@babylonjs/core';
import {  } from '@babylonjs/gui'
import  '@fortawesome/fontawesome-free/js/all.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWalking} from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-modal';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import env from '../models/environment.dds'

const totalFrame = 91
var camera = null
const imgRatio = 615/1366
const visible_const = 0.5

const customStyles = {
  content : {
    top                   : '30%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -30%)',
    width: "60%",
    minWidth: "480px",
    padding: 0
  },
  overlay : {
    backgroundColor: 'rgba(0, 0, 0, 0.25)'
  }
}

const groupItems = ["AVAILABLE", "RESERVED", "SOLD"]
const keys = ["blue", "red", "green"]


Modal.setAppElement('#root')

export default class IMPRO extends Component{
  constructor(props){
    super(props)

    var states = []
    for(let i=0; i<5; i++){
      states[i]={angle: Math.PI * (i+4)/ 8, frame: totalFrame*i/4}
    }

    this.state={
      salt : 0,
      modelObj : null,
      backgroundTextures : [],
      backgroundLayer : null,
      timedump : 0,
      imgnum : 0,
      selectedItem : 4,
      originRotate: Math.PI,
      originFrame: 0,
      states,
      canvasWidth: 0,
      pageStep: "logo" ,
      modalIsOpen: false,
      firstTimeLoad: true,
      imgOrigin: 0,
      startFlag : false,
      selectedAvailable:  {red:false, green: false, blue: false},
      meshGroup : {red:[], green: [], blue: []},
      visible_value : 0.0
    }   
    
  }

  select_item(index){
    let {selectedItem, originFrame, originRotate, states} = this.state
    // console.log("click item : ", index, states[index])   

    if(selectedItem !== index){
      selectedItem = index
      originFrame = states[index].frame
      originRotate = states[index].angle
      this.setState({selectedItem, originFrame, originRotate})
    }
  }

  select_available(index){
    let {selectedAvailable,  modelObj} = this.state
    console.log("click item : ", index)   
    selectedAvailable[keys[index]] = !selectedAvailable[keys[index]]

    modelObj.map(item=>{
      item.visibility = 0.0
    })


    this.setState({selectedAvailable})
  }

  onSceneReady = (scene) => {
    let {backgroundLayer, backgroundTextures, meshGroup} = this.state
    let engine = scene.getEngine();
    engine.enableOfflineSupport = false;
    var canvas = document.getElementById("my-canvas")
    var assetsManager = new AssetsManager(scene)

    for(let i=0; i<totalFrame; i++){
      if(i<10){
        assetsManager.addTextureTask("texture task",  require(`./assets/Animation_2/Animation_2_000${i}.jpg`))
      }else{
        assetsManager.addTextureTask("texture task",  require(`./assets/Animation_2/Animation_2_00${i}.jpg`))
      }
    }
    for(let i=0; i<totalFrame; i++){
      if(i<10){
        assetsManager.addTextureTask("texture task",  require(`./assets/Animation_1/Animation_1_000${i}.jpg`))
      }else{
        assetsManager.addTextureTask("texture task",  require(`./assets/Animation_1/Animation_1_00${i}.jpg`))
      }
    }
  
   

    var meshTask = assetsManager.addMeshTask('main', '', '/assets/models/', 'proxy_boxes.babylon')
    assetsManager.useDefaultLoadingScreen = true
    let that = this  

    scene.ambientColor = new Color3(1, 1, 1);
  

    var redMat =  new StandardMaterial("redMat", scene);
    redMat.ambientColor = new Color3(1, 0.7, 0.4);
    redMat.alpha = 0.99

    var greenMat = new StandardMaterial("greenMat", scene);
    greenMat.ambientColor = new Color3(0, 1, 0);
    greenMat.alpha = 0.999;


    var blueMat = new StandardMaterial("blueMat", scene);
    blueMat.ambientColor = new Color3(0, 0, 1  );
    blueMat.alpha = 0.999;



    assetsManager.onFinish = function (tasks) {

        const task = tasks[tasks.length-1]
        const newMeshes = task.loadedMeshes
        that.setState({modelObj:newMeshes}) 
        if(newMeshes){
          newMeshes.map(model=>{
            model.visibility = 0.0
            model.actionManager = new ActionManager(scene)       
            model.overlayAlpha = 1  
            var rands = Math.round(Math.random() * 2)
            if (rands === 0){
              model.material = redMat
              meshGroup['red'].push(model)

            }else if(rands === 1){

              model.material = greenMat
              meshGroup['green'].push(model)

            }else if(rands === 2){

              model.material = blueMat
              meshGroup['blue'].push(model)

            }       

            model.actionManager.registerAction(
              new ExecuteCodeAction(ActionManager.OnPickTrigger, function () {
            

                if(model.visibility === visible_const)that.setState({modalIsOpen: true})

              })
            )
            model.outlineWidth = 0.04;
            model.renderOutline = true;
            model.outlineColor = new Color4(1, 1, 1, 0.7)
            console.log("model : ", model.name)            
          })          
          that.setState({meshGroup})
        }        
        backgroundLayer.texture = tasks[1].texture
        for(let i=0; i<totalFrame * 2; i++){
          let task = tasks[i]
          backgroundTextures.push(task.texture)
        }     

        that.setState({backgroundLayer, backgroundTextures})
        // var hdrTexture = new CubeTexture.CreateFromPrefilteredData( './assets/models/environment.dds', scene);
        // scene.environmentTexture = hdrTexture;
        // var renderer = scene.enableDepthRenderer()
        // myMaterial.emissiveTexture = renderer.getDepthMap(); 
        // console.log("render values : ", renderer.getDepthMap())
        // scene.disableDepthRenderer()
    } 
    meshTask.onError = (err)=>{
      console.log("error while model import : ", err)
    }
    assetsManager.load();
    backgroundLayer = new Layer("back0", null, scene)  

    this.setState({backgroundLayer})
    camera = new ArcRotateCamera("Camera", 0, -10, 10, new Vector3(0, 0, 0), scene);
    // Positions the camera overwriting alpha, beta, radius
    camera.setPosition(new Vector3(0, 0, 0))
    camera.fov = 0.620900881    
    camera.upperRadiusLimit = 40
    camera.lowerRadiusLimit = 40
    camera.attachControl(canvas, true)
    camera.beta = 1.5708
    camera.upperBetaLimit = 1.5708
    camera.lowerBetaLimit = 1.5708
    camera.upperAlphaLimit = camera.alpha
    camera.lowerAlphaLimit = camera.alpha
  }
  
  onRender = (scene) => {

    let {modelObj, pageStep, selectedAvailable, meshGroup, visible_value,  backgroundTextures, backgroundLayer, originRotate, states, canvasWidth, imgnum, firstTimeLoad, imgOrigin, startFlag} = this.state
    var canvas = document.getElementById("my-canvas")
    var bg = document.getElementById("overlay-screen")
    var handler = document.getElementsByClassName('control-bar')[0]
    


    if(canvas.clientWidth !== canvasWidth){
      canvas.height = canvas.clientWidth * imgRatio
      canvas.width = canvas.clientWidth

      if(bg)
        bg.style.height = canvas.height + 'px'

   
      this.setState({canvasWidth: canvas.clientWidth})
    }

    if(handler && (handler.style.top !== canvas.height * 0.85))
        handler.style.top = canvas.height * 0.85 + 'px'


    if(!startFlag) return

    if(!(modelObj && backgroundTextures))return

    if(Math.abs(originRotate-camera.alpha)>0.008){    
        camera.alpha = camera.alpha + 0.008 * Math.sign(originRotate-camera.alpha)      
        camera.lowerAlphaLimit = camera.alpha
        camera.upperAlphaLimit = camera.alpha

        modelObj.map(item=>{
          item.visibility = 0.0
        })

    }else if(firstTimeLoad){

      imgOrigin = totalFrame
      console.log("handler : ", handler)
      this.setState({firstTimeLoad: false, imgOrigin, pageStep: "main"})
      this.select_available(0)
      backgroundLayer.texture = backgroundTextures[totalFrame * 2 - 1]

    }else if(pageStep === "main"){       
      if(visible_value < visible_const){
        visible_value += 0.01
        visible_value = Math.round(visible_value * 100) / 100
        this.setState({visible_value})
      }

    }


    keys.map(key=>{
      if(selectedAvailable[key])
        meshGroup[key].map(model=>{

          if((camera.alpha > states[1].angle+0.05)&&(camera.alpha <= states[1].angle+0.3) && model.name.includes("side2")){
            model.visibility = visible_const * (camera.alpha - states[1].angle)/0.3
          }else if((camera.alpha <= states[1].angle+0.3) && model.name.includes("side2")){
            
          }else if((camera.alpha <= states[3].angle - 0.1) && (camera.alpha >= states[3].angle - 0.4) && model.name.includes("side1")){
            model.visibility = visible_const * (states[3].angle-0.1-camera.alpha)/0.3


          }else if((camera.alpha >= states[3].angle - 0.4) && model.name.includes("side1")){

          }else
            model.visibility = visible_value
        })


    })

    let imgPos = parseInt((camera.alpha * 2.0 / Math.PI - 1.0) * totalFrame)

    if(imgPos !== imgnum){
      imgnum = imgnum + Math.sign(imgPos - imgnum)
      if(imgnum<0) imgnum = 0

      backgroundLayer.texture = backgroundTextures[imgnum + imgOrigin]
      this.setState({imgnum, backgroundLayer})
    }


  }

  afterCloseModal(){
    console.log("dialog closed");
  }
  closeModal(){
    this.setState({modalIsOpen: false})
  }

  clickToExplore(){
    this.setState({startFlag: true})
  }


  render(){

    let {selectedItem, pageStep, modalIsOpen, selectedAvailable} = this.state
    let reuturnItems = []
    for(let i=0; i<5;i++){
      reuturnItems.push(
        <div className="bar-item" key={i} onClick={this.select_item.bind(this, i)}>
          {(selectedItem === i) &&<div className="bar-inner-side">
          </div>}
        </div>
      )
    }

    let controlItems = []


    for(let i=0; i<3;i++){
      controlItems.push(
        <div className="group-item" key={i} onClick={this.select_available.bind(this, i)}>
          <div className="bar-item"  >
          {(selectedAvailable[keys[i]]) && <div className={"bar-inner-side-"+i}></div>}
          </div> 
          &nbsp;&nbsp;&nbsp;
          {groupItems[i]}
        </div>
       
      )
    }


    return(
          <div>
            <div className="overflow">
              {(pageStep==="main")&&<div className="control-bar">
                <div className="bar-line">
                </div>
                <div className="bar-items">
                  {
                    reuturnItems            
                  }
                </div>

                <div className="bar-items-down">
                  {
                    controlItems            
                  }
                </div>

              </div>}
            </div>
            <SceneComponent antialias onSceneReady={this.onSceneReady}  onRender={this.onRender} id='my-canvas' />
            
            {(pageStep==="logo")&&<div className="overlay-screen" id="overlay-screen">
              <div>
                <div className="logo-mark">
                  <div className="logo-mark-bg">
                  </div>
                  <div className = "logo-mark-content">
                    <h2>Miami Beach, FL</h2>
                    <p>3315 Collins Ave #PH-A</p>
                  </div>                  
                </div>
              </div>
                <div className="bottom-side">
                  <button onClick={this.clickToExplore.bind(this)}> 
                    Click To Explore &nbsp;&nbsp;<FontAwesomeIcon icon={faWalking}/>
                  </button>
                
                  <h1>Start Your Luxury Condo Search</h1>
                 </div>
            </div>}

            <Modal
              isOpen={modalIsOpen}
              onRequestClose={this.afterCloseModal.bind(this)}
              style={customStyles}
              contentLabel="Example Modal">          
                <div className="modal-class-img">
                  <h2>Starting At: $5770</h2>
                  <div className="modal-items">
                    <div className="modal-item">
                      <img src={require('./assets/Modal_Assets/image_1.jpg')}/>
                    </div>
                    <div className="modal-item">
                      <img src={require('./assets/Modal_Assets/image_2.jpg')}/>
                    </div>
                    <div className="modal-item">
                      <img src={require('./assets/Modal_Assets/image_3.jpg')}/>
                    </div>
                    
                  </div>
                  {/* <img src={require('./assets/imgs/modal-bg.png')}  /> */}
                </div>

                <div className="modal-class-down">

                <div className="down-side-item" >
                    <div className="item-class">
                        <img style={{width: '100%'}} src={require('./assets/Modal_Assets/3d_floorplan.jpg')}/>
                      
                    </div>
                    


                  </div>



                  <div className="down-side-item" >
                    <div className="item-class">

                      <p>2 Bedroom</p>
                      <p>2 Bathroom</p>
                      <p>1578 sQuare Feet</p>

                    </div>
                    

                    <div className="button-div">
                          <a href="https://www.insidemaps.com/app/walkthrough-v2/?projectId=rBDgE0al7n&env=production&floorId=aYVLdKYt2G&spinId=nqCD8sO0K2&quatX=-0.030&quatY=0.236&quatZ=0.007&quatW=0.971&fov=65.0" rel="noopener noreferrer" target="_blank"> 
                           LEARN MORE
                          </a>
                    </div>

                  </div>

                  <div className="down-side-item" >
                    <div className="item-class" style={{display: "flex",  justifyContent: "center", alignItems: "center", marginBottom: 42, marginTop: 0}}>

                      <img src={require('./assets/Modal_Assets/virtual_tour.jpg')}/>

                    </div>
                    

                    <div className="button-div">
                          <a href="https://www.insidemaps.com/app/walkthrough-v2/?projectId=rBDgE0al7n&env=production&floorId=aYVLdKYt2G&spinId=nqCD8sO0K2&quatX=-0.030&quatY=0.236&quatZ=0.007&quatW=0.971&fov=65.0" rel="noopener noreferrer" target="_blank"> 
                            LOOK INSIDE
                          </a>
                    </div>

                  </div>
                 
                  
                </div>

                <div className="exit-button" onClick={this.closeModal.bind(this)}>
                  <img src={require('./assets/imgs/exit-btn.png')} />
                </div>
              </Modal>
              </div>
      )
  }
}
  